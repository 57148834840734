.blinking_cursor {
    color: gray;
    animation-name: blink;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
}

@keyframes blink {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
